<template>
  <div class="animated fadeIn">
    <b-card class="min-vh-100">
      <b-row>
        <div class="display-table full-width mb-2">
          <strong class="card-title ml-3">Setting</strong>
        </div>
      </b-row>
      <ResponseAlert ref="responsePosition" />
      <b-tabs content-class="mt-3 border-0">
        <!-- Homepage tab -->
        <b-tab title="Homepage" active>
          <div class="mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <b-button v-b-modal.homepage> Add Advertisement </b-button>

              <div class="d-flex flex-row">
                <div class="d-flex flex-row align-align-items-baseline mr-3">
                  <div
                    class="bg-white rounded-circle shadow mr-2"
                    style="height: 20px; width: 20px"
                  ></div>
                  <p>Section</p>
                </div>
                <div class="d-flex flex-row align-align-items-baseline">
                  <div
                    class="bg-primary rounded-circle shadow mr-2"
                    style="height: 20px; width: 20px"
                  ></div>
                  <p>Advertisement</p>
                </div>
              </div>
            </div>

            <b-modal
              id="homepage"
              centered
              title="Add Advertisement"
              hide-footer
            >
              <label class="m-0 mb-1" for="">Ads</label>
              <b-form-select
                autofocus
                class="w-100 mb-3"
                v-model="homepage.vars.ads"
                :options="masters.ads"
              ></b-form-select>

              <label class="m-0 mb-1" for="">Ads Position</label>
              <b-form-select
                class="w-100 mb-4"
                v-model="homepage.vars.position"
                :options="masters.ads_position"
              ></b-form-select>
              <b-button
                :disabled="disabledSave('homepage')"
                variant="primary"
                class="w-100 mt-2"
                @click="addAds('homepage')"
              >
                Save
              </b-button>
            </b-modal>
          </div>

          <draggable v-model="homepage.values">
            <transition-group>
              <div v-for="(element, index) in homepage.values" :key="index">
                <div
                  v-if="element.type === 'news'"
                  class="shadow d-block text-center text-uppercase py-4 mb-3 rounded-lg position-relative"
                  :class="element.is_show ? 'bg-white' : 'bg-gray-300'"
                  style="cursor: move"
                >
                  <p
                    @keypress.prevent.enter
                    v-b-tooltip.hover
                    title="Click to rename section"
                    contenteditable
                    @blur="updateNewsSection($event, element)"
                    class="h2 font-weight-bold"
                    style="
                      cursor: pointer;
                      display: inline-block;
                      min-width: 2.5rem;
                    "
                    v-text="element.name"
                  ></p>
                  <small v-if="!element.is_show">(hidden)</small>
                  <i
                    v-b-tooltip.hover
                    :title="element.is_show ? 'Hide' : 'Show'"
                    @click="toggleShowSection(element)"
                    class="p-2 fa fa-lg position-absolute"
                    :class="element.is_show ? 'fa-eye' : 'fa-eye-slash'"
                    style="right: 1.5rem; top: 1.8rem; cursor: pointer"
                  ></i>
                </div>
                <div
                  v-else
                  class="bg-primary shadow d-block text-white text-center text-uppercase rounded-lg py-2 mb-3 position-relative"
                >
                  <p style="cursor: move">
                    <span class="h2 font-weight-bold">{{ element.name }}</span>
                    <small v-if="element.position">
                        {{ `(${element.position.replaceAll("_", " ")})` }}
                    </small>
                  </p>
                  <button
                    @click="homepage.values.splice(index, 1)"
                    title="Delete"
                    class="btn-danger rounded-circle position-absolute"
                    style="top: -10px; right: -10px"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </transition-group>
          </draggable>

          <div class="mt-5">
            <b-button
              type="button"
              @click="setPosition('homepage')"
              variant="primary"
              class="btn-min-width px-5 rounded"
            >
              Save
            </b-button>
          </div>
        </b-tab>
        <!-- Section tab -->
        <b-tab title="Section">
          <div class="d-flex justify-content-between align-items-center">
            <b-form-select
              class="w-25 mb-4"
              v-model="section.vars.section"
              :options="masters.sections"
            ></b-form-select>
            <div class="d-flex flex-row">
              <div class="d-flex flex-row align-align-items-baseline mr-3">
                <div
                  class="bg-gray-300 rounded-circle shadow mr-2"
                  style="height: 20px; width: 20px"
                ></div>
                <p>Line break</p>
              </div>
              <div class="d-flex flex-row align-align-items-baseline">
                <div
                  class="bg-primary rounded-circle shadow mr-2"
                  style="height: 20px; width: 20px"
                ></div>
                <p>Advertisement</p>
              </div>
            </div>
          </div>
          <draggable v-model="section.values">
            <transition-group>
              <div v-for="(element, index) in section.values" :key="index">
                <div
                  v-if="element.type === 'break'"
                  class="bg-gray-300 shadow d-block text-center text-uppercase py-2 mb-3 rounded-lg position-relative"
                >
                  <p class="h2 font-weight-bold" style="cursor: move">
                    {{ element.name }} Line Break (NEWS)
                  </p>
                  <button
                    @click="section.values.splice(index, 1)"
                    title="Delete"
                    class="btn-danger rounded-circle position-absolute"
                    style="top: -10px; right: -10px"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
                <div
                  v-else
                  class="bg-primary shadow d-block text-white text-center text-uppercase rounded-lg py-2 mb-3 position-relative"
                >
                  <p style="cursor: move">
                    <span class="h2 font-weight-bold">{{ element.name }}</span>
                    <small v-if="element.position">
                        {{ `(${element.position.replaceAll("_", " ")})` }}
                    </small>
                  </p>
                  <button
                    @click="section.values.splice(index, 1)"
                    title="Delete"
                    class="btn-danger rounded-circle position-absolute"
                    style="top: -10px; right: -10px"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div v-if="section.vars.section">
            <div class="mt-5">
              <b-button v-b-modal.section> Add Advertisement </b-button>

              <b-modal
                id="section"
                centered
                title="Add Advertisement"
                hide-footer
              >
                <label class="m-0 mb-1" for="">Ads</label>
                <b-form-select
                  autofocus
                  class="w-100 mb-3"
                  v-model="section.vars.ads"
                  :options="masters.ads"
                ></b-form-select>

                <label class="m-0 mb-1" for="">Ads position</label>
                <b-form-select
                  class="w-100 mb-4"
                  v-model="section.vars.position"
                  :options="masters.ads_position"
                ></b-form-select>
                <b-button
                  :disabled="disabledSave('section')"
                  variant="primary"
                  class="w-100"
                  @click="addAds('section')"
                >
                  Save
                </b-button>
              </b-modal>

              <b-button v-b-modal.break-section> Add Break </b-button>

              <b-modal
                id="break-section"
                centered
                title="Add Break"
                hide-footer
              >
                <b-form-group label="Total line break" class="mb-5">
                  <b-form-input
                    v-model="breakComputedSection"
                    @keydown.enter="addBreaks('section')"
                    @keypress="checkValidationNumber"
                    type="number"
                  />
                </b-form-group>
                <b-button
                  variant="primary"
                  class="w-100"
                  @click="addBreaks('section')"
                >
                  Save
                </b-button>
              </b-modal>
            </div>
            <div class="mt-4">
              <b-button
                type="button"
                variant="primary"
                class="btn-min-width px-5 rounded"
                @click="setPosition('section', 0, section.vars.section.data)"
              >
                Save
              </b-button>
            </div>
          </div>
          <div v-else>
            <h6 v-if="masters.sections.length === 1" class="text-danger">
              Please create some section to change position
            </h6>
            <h6 v-else class="text-danger">
              Select some section to change position
            </h6>
          </div>
        </b-tab>
        <!-- Category tab -->
        <b-tab title="Category">
          <div class="d-flex justify-content-between align-items-center">
            <b-form-select
              class="w-25 mb-4"
              v-model="category.vars.category"
              :options="masters.categoriesNews"
            ></b-form-select>
            <div class="d-flex flex-row">
              <div class="d-flex flex-row align-align-items-baseline mr-3">
                <div
                  class="bg-gray-300 rounded-circle shadow mr-2"
                  style="height: 20px; width: 20px"
                ></div>
                <p>Line break</p>
              </div>
              <div class="d-flex flex-row align-align-items-baseline">
                <div
                  class="bg-primary rounded-circle shadow mr-2"
                  style="height: 20px; width: 20px"
                ></div>
                <p>Advertisement</p>
              </div>
            </div>
          </div>
          <draggable v-model="category.values">
            <transition-group>
              <div v-for="(element, index) in category.values" :key="index">
                <div
                  v-if="element.type === 'break'"
                  class="bg-gray-300 shadow d-block text-center text-uppercase py-2 mb-3 rounded-lg position-relative"
                >
                  <p class="h2 font-weight-bold" style="cursor: move">
                    {{ element.name }} Line Break (NEWS)
                  </p>
                  <button
                    @click="category.values.splice(index, 1)"
                    title="Delete"
                    class="btn-danger rounded-circle position-absolute"
                    style="top: -10px; right: -10px"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>

                <div
                  v-else-if="element.type === 'news'"
                  class="shadow d-block text-center text-uppercase py-2 mb-3 rounded-lg position-relative"
                  :class="element.is_show ? 'bg-white' : 'bg-gray-300'"
                  style="cursor: move"
                >
                  <p
                    @keypress.prevent.enter
                    v-b-tooltip.hover
                    title="Click to rename section"
                    contenteditable
                    @blur="updateNewsSection($event, element)"
                    class="h2 font-weight-bold"
                    style="
                      cursor: pointer;
                      display: inline-block;
                      min-width: 2.5rem;
                    "
                    v-text="element.name"
                  ></p>
                  <small v-if="!element.is_show">(hidden)</small>
                  <i
                    v-b-tooltip.hover.lefttop
                    :title="element.is_show ? 'Hide' : 'Show'"
                    @click="toggleShowSection(element)"
                    class="p-2 fa fa-lg position-absolute"
                    :class="element.is_show ? 'fa-eye' : 'fa-eye-slash'"
                    style="right: 1.5rem; top: .8rem; cursor: pointer"
                  ></i>

                  <button
                    @click="category.values.splice(index, 1)"
                    title="Delete"
                    class="btn-danger rounded-circle position-absolute"
                    style="top: -10px; right: -10px"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
                <div
                  v-else
                  class="bg-primary shadow d-block text-white text-center text-uppercase rounded-lg py-2 mb-3 position-relative"
                >
                  <p style="cursor: move">
                    <span class="h2  font-weight-bold">
                        {{ element.name }}
                    </span>
                    <small v-if="element.position">
                        {{ `(${element.position.replaceAll("_", " ")})` }}
                    </small>
                  </p>
                  <button
                    @click="category.values.splice(index, 1)"
                    title="Delete"
                    class="btn-danger rounded-circle position-absolute"
                    style="top: -10px; right: -10px"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div v-if="category.vars.category">
            <div class="mt-5">
              <b-button v-b-modal.section-category> Add Section </b-button>

              <b-modal
                id="section-category"
                centered
                title="Add Section"
                hide-footer
              >
                <label class="m-0 mb-1" for="">Section</label>
                <b-form-select
                  autofocus
                  class="w-100 mb-3"
                  v-model="category.vars.section"
                  :options="defaultSectionsFilter"
                ></b-form-select>
                <b-button
                  variant="primary"
                  class="w-100"
                  @click="addSections('category')"
                >
                  Add
                </b-button>
              </b-modal>

              <b-button v-b-modal.category> Add Advertisement </b-button>

              <b-modal
                id="category"
                centered
                title="Add Advertisement"
                hide-footer
              >
                <label class="m-0 mb-1" for="">Ads</label>
                <b-form-select
                  autofocus
                  class="w-100 mb-3"
                  v-model="category.vars.ads"
                  :options="masters.ads"
                ></b-form-select>

                <label class="m-0 mb-1" for="">Ads position</label>
                <b-form-select
                  class="w-100 mb-4"
                  v-model="category.vars.position"
                  :options="masters.ads_position"
                ></b-form-select>
                <b-button
                  :disabled="disabledSave('category')"
                  variant="primary"
                  class="w-100"
                  @click="addAds('category')"
                >
                  Save
                </b-button>
              </b-modal>

              <b-button v-b-modal.break-category> Add Break </b-button>

              <b-modal
                id="break-category"
                centered
                title="Add Break"
                hide-footer
              >
                <b-form-group label="Total line break" class="mb-5">
                  <b-form-input
                    v-model="breakComputed"
                    @keydown.enter="addBreaks('category')"
                    @keypress="checkValidationNumber"
                    type="number"
                  />
                </b-form-group>
                <b-button
                  variant="primary"
                  class="w-100"
                  @click="addBreaks('category')"
                >
                  Save
                </b-button>
              </b-modal>
            </div>
            <div class="mt-4">
              <b-button
                type="button"
                variant="primary"
                class="btn-min-width px-5 rounded"
                @click="setPosition('category', category.vars.category.id)"
              >
                Save
              </b-button>
            </div>
          </div>
          <div v-else>
            <h6 v-if="masters.categoriesNews.length === 1" class="text-danger">
              Please create some category to change position
            </h6>
            <h6 v-else class="text-danger">
              Select some category to change position
            </h6>
          </div>
        </b-tab>
        <!-- Website tab -->
        <b-tab title="Website">
          <form @submit.prevent="updateWebsiteSetting">
            <b-row>
              <ResponseAlert ref="response" />
              <b-col lg="9" class="mb-2">
                <b-row>
                  <b-col md="8" order="2" order-md="1">
                    <b-form-group
                      v-bind:class="{ 'is-invalid': errors.has('site title') }"
                      label="Site Title"
                    >
                      <b-form-input
                        v-model="models.content.site_title"
                        v-validate="'required'"
                        data-vv-name="site title"
                      >
                      </b-form-input>
                      <div v-if="errors.has('site title')">
                        <p class="text-danger">
                          {{ errors.first("site title") }}
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group label="Company Name">
                      <b-form-input v-model="models.content.company_name">
                      </b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Email"
                      v-bind:class="{ 'is-invalid': errors.has('email') }"
                    >
                      <b-form-input
                        v-model="models.content.email"
                        type="email"
                        v-validate="'email'"
                        data-vv-name="email"
                      >
                      </b-form-input>
                      <div v-if="errors.has('email')">
                        <p class="text-danger">{{ errors.first("email") }}</p>
                      </div>
                    </b-form-group>
                    <b-form-group label="Phone Number">
                      <b-form-input
                        v-model="models.content.phone"
                        type="number"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" order="1" order-md="2" class="mb-4 mb-md-0">
                    <div class="image-input">
                      <b-img
                        :src="getUrlOfFile(models.content.logo, true)"
                        class="mb-2"
                      />
                      <b-button
                        class="btn-upload btn-block"
                        @click="$refs.filemanager.show('image')"
                      >
                        <i class="fa fa-upload"></i> Upload Logo
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <label for="" class="w-100 text-dark">
                      Address
                      <b-form-textarea
                        id="textarea"
                        placeholder="Type address..."
                        rows="5"
                        max-rows="7"
                        v-model="models.content.address"
                      ></b-form-textarea>
                    </label>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col md="6">
                    <b-form-group
                      label="Latitude"
                      v-bind:class="{ 'is-invalid': errors.has('latitude') }"
                    >
                      <b-form-input
                        v-model="models.content.latitude"
                        v-validate="'required'"
                        type="number"
                        step="any"
                        data-vv-name="latitude"
                      >
                      </b-form-input>
                      <div v-if="errors.has('latitude')">
                        <p class="text-danger">
                          {{ errors.first("latitude") }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Longitude"
                      v-bind:class="{ 'is-invalid': errors.has('longitude') }"
                    >
                      <b-form-input
                        v-model="models.content.longitude"
                        v-validate="'required'"
                        type="number"
                        step="any"
                        data-vv-name="longitude"
                      >
                      </b-form-input>
                      <div v-if="errors.has('longitude')">
                        <p class="text-danger">
                          {{ errors.first("longitude") }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col md="4">
                    <b-form-group
                      label="Link Facebook"
                      v-bind:class="{
                        'is-invalid': errors.has('link facebook'),
                      }"
                    >
                      <b-form-input
                        v-model="models.content.facebook"
                        v-validate="'url'"
                        data-vv-name="link facebook"
                      >
                      </b-form-input>
                      <div v-if="errors.has('link facebook')">
                        <p class="text-danger">
                          {{ errors.first("link facebook") }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Link Instagram"
                      v-bind:class="{
                        'is-invalid': errors.has('link instagram'),
                      }"
                    >
                      <b-form-input
                        v-model="models.content.instagram"
                        v-validate="'url'"
                        data-vv-name="link instagram"
                      >
                      </b-form-input>
                      <div v-if="errors.has('link instagram')">
                        <p class="text-danger">
                          {{ errors.first("link instagram") }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Link Youtube"
                      v-bind:class="{
                        'is-invalid': errors.has('link youtube'),
                      }"
                    >
                      <b-form-input
                        v-model="models.content.youtube"
                        v-validate="'url'"
                        data-vv-name="link youtube"
                      >
                      </b-form-input>
                      <div v-if="errors.has('link youtube')">
                        <p class="text-danger">
                          {{ errors.first("link youtube") }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col md="6">
                    <div class="d-flex">
                      <b-button
                        @click="updateWebsiteSetting"
                        type="submit"
                        variant="primary"
                        class="w-50 rounded"
                      >
                        Save
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </form>
          <Filemanager
            ref="filemanager"
            name="image"
            @selectMedia="selectMedia"
          />
        </b-tab>
        <!-- Market Data tab -->
        <b-tab title="Market Data">
          <form @submit.prevent="saveMarketData()">
            <ResponseAlert ref="responseMarket" />
            <b-row>
              <b-col lg="10" class="d-flex">
                <b-form-group
                  v-bind:class="{ 'is-invalid': errors.has('market name') }"
                  class="mx-1"
                >
                  <b-form-input
                    v-model="market.models.name"
                    data-vv-name="market name"
                    placeholder="Market Name"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  v-bind:class="{ 'is-invalid': errors.has('value') }"
                  class="mx-1"
                >
                  <b-form-input
                    v-model="market.models.value"
                    type="number"
                    data-vv-name="value"
                    placeholder="Value"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  v-bind:class="{ 'is-invalid': errors.has('up value') }"
                  class="mx-1"
                >
                  <b-form-input
                    v-model="market.models.up_value"
                    type="number"
                    data-vv-name="up value"
                    placeholder="Up Value"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  v-bind:class="{ 'is-invalid': errors.has('up percentage') }"
                  class="mx-1"
                >
                  <b-form-input
                    v-model="market.models.up_percentage"
                    type="number"
                    data-vv-name="up percentage"
                    placeholder="Up Percentage"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  v-bind:class="{ 'is-invalid': errors.has('down value') }"
                  class="mx-1"
                >
                  <b-form-input
                    v-model="market.models.down_value"
                    type="number"
                    data-vv-name="down value"
                    placeholder="Down Value"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  v-bind:class="{ 'is-invalid': errors.has('down percentage') }"
                  class="mx-1"
                >
                  <b-form-input
                    v-model="market.models.down_percentage"
                    type="number"
                    data-vv-name="down percentage"
                    placeholder="Down percentage"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="2" class="text-center">
                <b-button
                  @click="saveMarketData()"
                  size="sm"
                  class="w-75 mt-1"
                  variant="primary"
                  >Save</b-button
                >
              </b-col>
            </b-row>
          </form>

          <hr />
          <h5 class="font-weight-bold">Current Market Data</h5>
          <form
            @submit.prevent="saveMarketData(market)"
            v-for="(market, index) in market.rows"
            :key="index"
          >
            <b-row class="mt-3 align-items-center">
              <b-col lg="10" class="d-flex">
                <b-form-group
                  class="mx-1"
                  label="Market Name"
                  v-bind:class="{
                    'is-invalid': errors.has(`market name - ${index + 1}`),
                  }"
                >
                  <b-form-input
                    v-model="market.name"
                    v-validate="'required'"
                    :data-vv-name="`market name - ${index + 1}`"
                    size="sm"
                  >
                  </b-form-input>
                  <div v-if="errors.has(`market name - ${index + 1}`)">
                    <p class="text-danger">
                      {{ errors.first(`market name - ${index + 1}`) }}
                    </p>
                  </div>
                </b-form-group>

                <b-form-group class="mx-1" label="Value">
                  <b-form-input
                    v-model="market.value"
                    type="number"
                    data-vv-name="value"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group class="mx-1" label="Up Value">
                  <b-form-input
                    v-model="market.up_value"
                    type="number"
                    data-vv-name="up value"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group class="mx-1" label="Up Percentage">
                  <b-form-input
                    v-model="market.up_percentage"
                    type="number"
                    data-vv-name="up percentage"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group class="mx-1" label="Down Value">
                  <b-form-input
                    v-model="market.down_value"
                    type="number"
                    data-vv-name="down value"
                    size="sm"
                    required
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group class="mx-1" label="Down Percentage">
                  <b-form-input
                    v-model="market.down_percentage"
                    type="number"
                    data-vv-name="down percentage"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="2" class="text-center d-flex">
                <b-button
                  button="submit"
                  @click="saveMarketData(market)"
                  size="sm"
                  class="w-75"
                  variant="primary"
                  >Update</b-button
                >
                <button
                  type="button"
                  @click="destroyMarket(market.id)"
                  title="Delete"
                  class="btn-action"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </b-col>
            </b-row>
          </form>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ResponseAlert from "@/components/response_alert";
import Filemanager from "@/components/file_manager";
import api from "@/utils/api.js";
import { getUrlOfFile, checkValidationNumber } from "@/utils/helpers.js";

export default {
  name: "Setting",
  components: {
    ResponseAlert,
    Filemanager,
    draggable,
  },
  data() {
    return {
      getUrlOfFile,
      checkValidationNumber,
      masters: {
        categoriesNews: [
          {
            value: null,
            text: "Select Category News",
          },
        ],
        defaultSections: [
          {
            value: null,
            text: "Select Section",
            disabled: true,
          },
          {
            value: "latest_news",
            text: "Latest News",
          },
          {
            value: "top_stories",
            text: "Top Stories",
          },
          {
            value: "most_commented_news",
            text: "Most Commented News",
          },
          {
            value: "popular_news",
            text: "Popular News",
          },
          {
            value: "editor_choice",
            text: "Editor's Choice",
          },
        ],
        sections: [
          {
            value: null,
            text: "Select Section",
          },
        ],
        ads: [
          {
            value: null,
            text: "Select Advertisement",
          },
        ],
        ads_position: [
          {
            value: "popup",
            text: "Popup",
          },
          {
            value: "banner",
            text: "Banner",
          },
          {
            value: "banner_list",
            text: "Banner List",
          },
          {
            value: "giant_banner",
            text: "Giant Banner",
          },
        ],
      },
      // Homepage tab data
      homepage: {
        models: {},
        vars: {
          ads: null,
          position: "popup",
        },
        values: [],
      },
      // Category tab data
      section: {
        models: {},
        vars: {
          section: null,
          ads: null,
          position: "popup",
          break: "",
        },
        values: [],
      },
      // Category tab data
      category: {
        models: {},
        vars: {
          category: null,
          ads: null,
          position: "popup",
          break: "",
          section: null,
        },
        values: [],
      },
      // Website tab data
      models: {
        type: "setting_website",
        content: {
          site_title: "",
          company_name: "",
          email: "",
          phone: "",
          address: "",
          facebook: "",
          instagram: "",
          youtube: "",
          logo: "",
          latitude: "",
          longitude: "",
        },
      },
      market: {
        models: {
          name: "",
          value: null,
          up_value: null,
          up_percentage: null,
          down_value: null,
          down_percentage: null,
        },
        rows: [],
      },
    };
  },
  created() {
    this.getOptionData();
    this.getWebsiteData();
    this.getMarketData();
    this.getPosition("homepage");
  },
  computed: {
    categoryChange() {
      return JSON.stringify(this.category.vars.category);
    },
    sectionChange() {
      return JSON.stringify(this.section.vars.section);
    },
    defaultSectionsFilter() {
      return this.masters.defaultSections.filter(section => {
        return !this.category.values.map(val => val.data).includes(section.value)
      });
    },
    breakComputed: {
      get() {
        return this.category.vars.break;
      },
      set(val) {
        this.category.vars.break = val;
      },
    },
    breakComputedSection: {
      get() {
        return this.section.vars.break;
      },
      set(val) {
        this.section.vars.break = val;
      },
    },
  },
  watch: {
    categoryChange() {
      if (this.category.vars.category) {
        this.getPosition("category", this.category.vars.category.id);
        this.category.models.ref_id = this.category.vars.category.id;
      } else {
        this.category.models.position = [];
        this.category.values = [];
      }
    },
    sectionChange() {
      if (this.section.vars.section) {
        this.getPosition("section", 0, this.section.vars.section.data);
        this.section.models.ref_id = 0;
      } else {
        this.section.models.position = [];
        this.section.values = [];
      }
    },
  },
  methods: {
    toggleShowSection(element) {
      element.is_show = !element.is_show;
    },
    updateNewsSection(event, element) {
      if (event.target.textContent.length < 1) {
        return this.$showToast("Failed", `Cannot empty sections`, "danger");
      }
      element.name = event.target.textContent;
    },
    getOptionData() {
      const categoriesNews = this.$axios.get(api.news_category, {
        params: { status: "active", orderby: "name", sort: "asc" },
      });
      const ads = this.$axios.get(api.advertisement, {
        params: { status: "publish", orderby: "name", sort: "asc" },
      });

      Promise.all([categoriesNews, ads]).then((res) => {
        const options = ["categoriesNews", "ads"];
        options.forEach((opt, idx) => {
          if (opt === "categoriesNews" && res[0].data.data.rows.length) {
            this.category.vars.category = res[0].data.data.rows[0];
          }

          res[idx].data.data.rows.forEach((row) => {
            this.masters[opt].push({
              value: row,
              text: row.name,
            });
          });
        });
      });
    },
    getPosition(type, ref_id = 0, section) {
      let data = section ? section : type;
      this.$axios
        .get(api.setting_position, {
          params: {
            type: data,
            ref_id,
          },
        })
        .then((res) => {
          const { data } = res.data;

          if (data) {
            this[type].models.type = section ? "section" : data.type;
            this[type].models.ref_id = data.ref_id;
            this[type].models.position = [];
            this[type].values = [];

            if (type === "homepage") {
              this.masters.sections = [
                {
                  value: null,
                  text: "Select Section",
                },
              ];
            }
            data.position.forEach((pos, index) => {
              if(typeof pos.data !== 'object' && !pos.name) {
                pos.name = pos.data.replace('_', ' ').toUpperCase()
              }
              if (pos.data) {
                const data =
                  typeof pos.data === "object" ? pos.data.id : pos.data;
                const name =
                  typeof pos.data === "object" ? pos.data.name : pos.name;
                const formattedName = name.replaceAll("_", " ");
                let value = {
                  type: pos.type,
                  name: formattedName,
                  data,
                };

                if (pos.position) {
                  value.position = pos.position;
                }

                if (pos.type === "news") {
                  value.is_show = pos.is_show === undefined || null ? true : pos.is_show;
                }
                
                this[type].values.push(value);

                if (type === "homepage" && pos.type === "news") {
                  if (index == 0) {
                    this.section.vars.section = pos;
                  }
                  this.masters.sections.push({
                    value: pos,
                    text: formattedName,
                  });
                }
              }
            });
            
          }
        })
        .catch(() => {
          this[type].models.position = [];
          this[type].values = [];
        });
    },

    addAds(type) {
      let pass = true;
      if (this[type].vars.position === "popup") {
        this[type].values.forEach((val) => {
          if (val.type === "advertisement" && val.position === "popup") {
            pass = false;
            return this.$showToast(
              "Failed",
              `can only add 1 popup ads`,
              "danger"
            );
          }
        });
      }
      if (pass) {
        this[type].values.push({
          type: "advertisement",
          data: this[type].vars.ads.id,
          name: this[type].vars.ads.name,
          position: this[type].vars.position,
        });

        this[type].vars.ads = null;
        this[type].vars.position = "popup";
        this.$bvModal.hide(type);
      }
    },
    addBreaks(type) {
      if (this[type].vars.break) {
        this[type].values.push({
          type: "break",
          data: this[type].vars.break,
          name: this[type].vars.break,
        });

        this[type].vars.break = 0;
        this.$bvModal.hide(`break-${type}`);
      }
    },
    addSections(type) {
      if (this[type].vars.section) {
        this[type].values.push({
          type: "news",
          data: this[type].vars.section,
          name: this[type].vars.section.replace("_", " "),
          is_show: true
        });

        this[type].vars.section = null;
        this.$bvModal.hide(`section-${type}`);
      }
    },
    disabledSave(type) {
      return !this[type].vars.ads;
    },
    setPosition(type, ref_id = 0, section) {
      this[type].values.forEach((value) => {
        const pos = {
          name: value.name,
          type: value.type,
          data: value.data,
          is_show: value.is_show
        };

        if (value.position) {
          pos.position = value.position;
        }

        this[type].models.position.push(pos);
      });

      let models = this[type].models;  
      if (type === "category") {
        models.type = type;
        models.ref_id = ref_id;
      } else if (section) {
        models.type = section;
        models.ref_id = 0;
      }



      this.$axios
        .post(api.setting_position, models)
        .then((res) => {
          this[type].models.position = [];
          this[type].values = [];
          this.$refs.responsePosition.setResponse(res.data.message, "success");

          if (section && type !== "category") {
            this.getPosition(type, ref_id, section);
          } else {
            this.getPosition(type, ref_id);
          }
          window.scrollTo(0, 0);
        })
        .catch((error) =>
          this.$refs.responsePosition.setResponse(
            error.response.data.message,
            "danger"
          )
        );
    },

    getWebsiteData() {
      this.$axios
        .get(api.content, {
          params: {
            type: "setting_website",
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.models.type = data.type;
          this.models.content = data.content;
        });
    },
    selectMedia(field, media) {
      this.models.content.logo = media.file;
    },
    updateWebsiteSetting() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.$axios
            .post(api.content, this.models)
            .then((res) => this.$refs.response.setResponse(res.data.message))
            .catch((error) =>
              this.$refs.response.setResponse(
                error.response.data.message,
                "danger"
              )
            );
        } else {
          window.scrollTo(0, 0);
        }
      });
    },
    getMarketData() {
      this.$axios
        .get(api.market_data, {
          params: {
            orderby: "id",
            sort: "desc",
          },
        })
        .then((res) => {
          this.market.rows = res.data.data.rows;
        });
    },
    destroyMarket(id) {
      const _ = this;
      _.$dialog
        .confirm("Are you sure want to delete this data?", {
          okText: "Yes",
          cancelText: "No",
        })
        .then(() => {
          _.$axios
            .delete(`${api.market_data}/${id}`)
            .then((res) => {
              _.$refs.responseMarket.setResponse(res.data.message, "success");
              _.getMarketData();
            })
            .catch((error) => {
              _.$refs.responseMarket.setResponse(
                error.response.data.message,
                "danger"
              );
            });
        });
    },
    saveMarketData(data) {
      const _ = this;

      const req = () => {
        let request = "";
        let message = "add";
        if (!data) {
          request = _.$axios.post(api.market_data, _.market.models);
        } else {
          message = "update";
          let models = {
            name: data.name,
            value: data.value,
            up_value: data.up_value,
            up_percentage: data.up_percentage,
            down_value: data.down_value,
            down_percentage: data.down_percentage,
          };
          request = _.$axios.put(`${api.market_data}/${data.id}`, models);
        }
        request
          .then(() => {
            this.$showToast("Success", `Success ${message} market data`);
            this.getMarketData();
            this.market.models = {
              name: "",
              value: null,
              up_value: null,
              up_percentage: null,
              down_value: null,
              down_percentage: null,
            };
          })
          .catch((error) =>
            _.$refs.responseMarket.setResponse(
              error.response.data.message,
              "danger"
            )
          );
      };
      if (!data) {
        this.$validator.validate().then((valid) => {
          if (valid) {
            req();
          }
        });
      } else {
        req();
      }
    },
  },
};
</script>
